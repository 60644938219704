import logo from '../images/logo.png'
import icon30nam from '../images/icon30nam.png'
import title from '../images/title.png'
import bg_button_active from '../images/bg_button_active.png'
import bg_button_normal from '../images/bg_button_normal.png'
import bg_1 from '../images/bg_1.png'
import Road from '../images/Road.png'
import image_right_header from '../images/image_right_header.png'
import image_left_header from '../images/image_left_header.png'
import { HashLink } from 'react-router-hash-link'
import { useState } from 'react'

const Header_content = () => {
  const [indexButton, SetIndexButton] = useState(0);
  const AnimaionButton = (index: number) => {
    SetIndexButton(index);
    setTimeout(() => {
      SetIndexButton(0);
    }, 200);
  }
  return (
    <>
      <div className='relative flex justify-center'>
        <div className='absolute z-[-1]'>
          <img src={bg_1} id='top' className='h-[100%] w-[100%]'></img>
        </div>
        <div className='absolute w-[100%] flex flex-col justify-end h-[100%]'>
          <div className='flex flex-row justify-between mb-[-26%]'>
            <img src={image_left_header} className='ml-[-2%] w-[19%] z-[1] h-[80%]'></img>
            <img src={Road} className='w-[36%] h-[130%]'></img>
            <img src={image_right_header} className='w-[21%] z-[1] h-[80%] pr-[-2%]'></img>
          </div>
        </div>
        <div className='flex flex-col items-center justify-center'>
          <div className='min-[1403px]:w-[1403px] max-[1403px]:w-[100%]'>
            <div>
              <div className='flex flex-row mt-[5%] justify-between'>
                <img src={logo} className='ml-[2%] max-sm:ml-[5%] max-sm:w-[20%] w-[18%] h-[90%]'></img>
                <img src={icon30nam} className='mr-[2%] max-sm:mr-[5%] max-sm:w-[12%] w-[10%]'></img>
              </div>
              <div className='flex justify-center mt-[3%]'>
                <img src={title} className='w-[81%] z-[1]'></img>
              </div>
              <h1 className='text-red-800'></h1>
              <div className='flex max-sm:hidden px-[2%] flex-row justify-between mt-[11%]'>
                <div className='relative z-[2] items-center flex justify-center w-[16%]'>
                  <HashLink smooth onClick={() => AnimaionButton(8)} to={"/#hanhtrinh30nam"}>
                    <img src={indexButton === 8 ? bg_button_active : bg_button_normal} className='w-[100%]'></img>
                  </HashLink>
                  <HashLink smooth onClick={() => AnimaionButton(8)} to={"/#hanhtrinh30nam"} className='absolute text-white montserrat-semibold leading-auto 2xl:text-[24] xl:text-[20px] lg:text-[16px] md:text-[12px] sm:text-[8px] max-sm:text-[4px]'>
                    <div>Hành trình</div>
                    <div>30 năm</div>
                  </HashLink>
                </div>
                <div className='relative z-[2] items-center flex justify-center w-[16%]'>
                  <HashLink smooth onClick={() => AnimaionButton(7)} to={"/#cachthucthamgia"}>
                    <img src={indexButton === 7 ? bg_button_active : bg_button_normal} className='w-[100%]'></img>
                  </HashLink>
                  <HashLink smooth onClick={() => AnimaionButton(7)} to={"/#cachthucthamgia"} className='absolute text-white montserrat-semibold leading-auto 2xl:text-[24] xl:text-[20px] lg:text-[16px] md:text-[12px] sm:text-[8px] max-sm:text-[4px]'>
                    <div>Cách thức</div>
                    <div>tham gia</div>
                  </HashLink>
                </div>
                <div className='relative z-[2] items-center flex justify-center w-[16%]'>
                  <HashLink smooth onClick={() => AnimaionButton(6)} to={"/#giaithuong"}>
                    <img src={indexButton === 6 ? bg_button_active : bg_button_normal} className='w-[100%]'></img>
                  </HashLink>
                  <HashLink smooth onClick={() => AnimaionButton(6)} to={"/#giaithuong"} className='absolute text-white montserrat-semibold text-xl leading-auto 2xl:text-[24] xl:text-[20px] lg:text-[16px] md:text-[12px] sm:text-[8px] max-sm:text-[4px]'>
                    <div>Giải thưởng</div>
                  </HashLink>
                </div>
                <div className='relative z-[2] items-center flex justify-center w-[16%]'>
                  <HashLink smooth onClick={() => AnimaionButton(5)} to={"/#khamphathuthach"}>
                    <img src={indexButton === 5 ? bg_button_active : bg_button_normal}></img>
                  </HashLink>
                  <HashLink smooth onClick={() => AnimaionButton(5)} to={"/#khamphathuthach"} className='absolute text-white montserrat-semibold leading-auto 2xl:text-[24] xl:text-[20px] lg:text-[16px] md:text-[12px] sm:text-[8px] max-sm:text-[4px]'>
                    <div>Khám phá</div>
                    <div>thử thách</div>
                  </HashLink>
                </div>
                <div className='relative z-[2] items-center flex justify-center w-[16%]'>
                  <HashLink target='_blank' onClick={() => AnimaionButton(9)} to={"https://app.adjust.com/163624zw"} className='w-[100%]'>
                    <img src={indexButton === 9 ? bg_button_active : bg_button_normal} ></img>
                  </HashLink>
                  <HashLink onClick={() => AnimaionButton(9)} to={"https://app.adjust.com/163624zw"} className='absolute text-white montserrat-semibold text-xl leading-auto 2xl:text-[24] xl:text-[20px] lg:text-[16px] md:text-[12px] sm:text-[8px] max-sm:text-[4px]'>
                    <div>Tải App ngay</div>
                  </HashLink>
                </div>
              </div>

              <div className='flex sm:hidden flex-col justify-between'>
                <div className='flex flex-row justify-evenly w-[100%]'>
                  <div className="relative z-[2] items-center flex justify-center w-[20%]">
                    <HashLink onClick={() => AnimaionButton(1)} to={"/#hanhtrinh30nam"}>
                      <img src={indexButton === 1 ? bg_button_active : bg_button_normal} className='w-[100%]'></img>
                    </HashLink>
                    <HashLink smooth onClick={() => AnimaionButton(1)} to={"/#hanhtrinh30nam"} className='absolute text-white montserrat-semibold leading-auto 2xl:text-[24] xl:text-[20px] lg:text-[16px] md:text-[12px] sm:text-[10px] max-sm:text-[10px]'>
                      <div>Hành trình</div>
                      <div>30 năm</div>
                    </HashLink>
                  </div>
                  <div className='relative z-[2] items-center flex justify-center w-[20%]'>
                    <HashLink smooth onClick={() => AnimaionButton(2)} to={"/#cachthucthamgia"}>
                      <img src={indexButton === 2 ? bg_button_active : bg_button_normal} className='w-[100%]'></img>
                    </HashLink>
                    <HashLink smooth onClick={() => AnimaionButton(2)} to={"/#cachthucthamgia"} className='absolute text-white montserrat-semibold leading-auto 2xl:text-[24] xl:text-[20px] lg:text-[16px] md:text-[12px] sm:text-[10px] max-sm:text-[10px]'>
                      <div>Cách thức</div>
                      <div>tham gia</div>
                    </HashLink>
                  </div>
                  <div className='relative z-[2] items-center flex justify-center w-[20%]'>
                    <HashLink smooth onClick={() => AnimaionButton(3)} to={"/#giaithuong"}>
                      <img src={indexButton === 3 ? bg_button_active : bg_button_normal} className='w-[100%]'></img>
                    </HashLink>
                    <HashLink smooth onClick={() => AnimaionButton(3)} to={"/#giaithuong"} className='absolute text-white montserrat-semibold text-xl leading-auto 2xl:text-[24] xl:text-[20px] lg:text-[16px] md:text-[12px] sm:text-[10px] max-sm:text-[10px]'>
                      <div>Giải thưởng</div>
                    </HashLink>
                  </div>
                  <div className='relative z-[2] items-center flex justify-center w-[20%]'>
                    <HashLink smooth onClick={() => AnimaionButton(4)} to={"/#khamphathuthach"}>
                      <img src={indexButton === 4 ? bg_button_active : bg_button_normal}></img>
                    </HashLink>
                    <HashLink smooth onClick={() => AnimaionButton(4)} to={"/#khamphathuthach"} className='absolute text-white montserrat-semibold leading-auto 2xl:text-[24] xl:text-[20px] lg:text-[16px] md:text-[12px] sm:text-[10px] max-sm:text-[10px]'>
                      <div>Khám phá</div>
                      <div>thử thách</div>
                    </HashLink>
                  </div>
                </div>
                <div className='flex flex-row justify-evenly w-[100%]'>
                  <div className='relative z-[2] items-center flex justify-center w-[20%]'>
                    <HashLink target='_blank' onClick={() => AnimaionButton(10)} to={"https://app.adjust.com/163624zw"} className='w-[100%]'>
                      <img src={indexButton === 10 ? bg_button_active : bg_button_normal}></img>
                    </HashLink>
                    <HashLink onClick={() => AnimaionButton(10)} to={"https://app.adjust.com/163624zw"} className='absolute text-white montserrat-semibold text-xl leading-auto 2xl:text-[24] xl:text-[20px] lg:text-[16px] md:text-[12px] sm:text-[10px] max-sm:text-[10px]'>
                      <div>Tải App ngay</div>
                    </HashLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header_content