import pize_title from '../images/image_pizes.png'
import spin_number from '../images/spin_number.png'
import prizes_image from '../images/prizes_image.png'
import prizes_image_mobile from '../images/prizes_image_mobile.png'
import bg_fire_reward from '../images/bg_fire_reward.png'
import bg_fire_reward_mobile from '../images/bg_fire_reward_mobile.png'
import store_prizes from '../images/store_prizes.png'
import time_button from '../images/time_button.png'
import time_result from '../images/time_result.png'
import bg_title_prize from '../images/bg_title_prize.png'
import coin from '../images/coin.png'
import frame from '../images/frame.png'
import text_cash from '../images/text_cash.png'
import line from '../images/line.png'
import line_white from '../images/line_white.png'
import text_vourcher from '../images/text_vourcher.png'
import text_gotit from '../images/text_gotit.png'
import image_voucher from '../images/image_voucher.png'
import image_gotit from '../images/image_gotit.png'
import kfc from '../images/kfc.png'
import canifornia from '../images/canifornia.png'
import galaxy from '../images/galaxy.png'
import coin_2 from '../images/coin_2.png'
import icon_spin from '../images/icon_spin.png'
import longcity from '../images/longcity.png'
import ticket_plane from '../images/ticket_plane.png'
import line_reward from '../images/line_reward.png'
import { useContext, useEffect, useState } from 'react'
import { HashLink } from 'react-router-hash-link'
import Infor_result_spin from './popups/infor_result_spin'
import { IValue, PopupContext } from './contexts/popupContext'


interface reward {
    title?: string,
    unclaimed: number,
    initialAmount: number,
}

interface rewards {
    cash: reward[]
    topup: reward[]
    voucherGotit: {
        KFC: reward,
        Galaxy_Play: reward,
        California_Fitness: reward
    }
}

const Reward = () => {
    const [linkReward, SetLinkReward] = useState("https://apps.apple.com/vn/app/shb-mobile/id538278798?l=vi&platform=ipad");
    const [isShowPopup, SetShowPopup] = useState("none");
    const value: IValue = useContext(PopupContext);
    const [dataReward, SetDataReward] = useState<rewards>({
        cash: [{
            unclaimed: 1,
            initialAmount: 1,
        }],
        topup: [{
            unclaimed: 1,
            initialAmount: 1,
        }],
        voucherGotit: {
            KFC: {
                unclaimed: 1,
                initialAmount: 1,
            },
            Galaxy_Play: {
                unclaimed: 1,
                initialAmount: 1,
            },
            California_Fitness: {
                unclaimed: 1,
                initialAmount: 1,
            }
        }
    }
    );

    const ChangeTextReward = () => {
        if (value.SetActivePopup !== undefined) {
            value.SetActivePopup(true);
        }
    }

    useEffect(() => {
        const targetDay = 1705021200000;
        const today = Date.now();
        if (today < targetDay) {
            SetLinkReward("");
        }
    }, []);

    useEffect(() => {
        const DataReward: rewards = {
            cash: [],
            topup: [],
            voucherGotit: {
                KFC: {
                    title: "",
                    unclaimed: 1,
                    initialAmount: 1,
                },
                Galaxy_Play: {
                    title: "",
                    unclaimed: 1,
                    initialAmount: 1,
                },
                California_Fitness: {
                    title: "",
                    unclaimed: 1,
                    initialAmount: 1,
                }
            }
        };
        fetch("https://game30-user-api.shb.com.vn/api/game/poolStatistic?campaignId=65140fbf8c5cf2c16ecfca11")
            .then(response => response.json())
            .then(resp => {
                DataReward.cash.push(resp.cash["Tiền mặt 500.000"]);
                DataReward.cash[DataReward.cash.length - 1].title = "Tiền mặt 500.000";
                DataReward.cash.push(resp.cash["Tiền mặt 200.000"]);
                DataReward.cash[DataReward.cash.length - 1].title = "Tiền mặt 200.000";
                DataReward.cash.push(resp.cash["Tiền mặt 100.000"]);
                DataReward.cash[DataReward.cash.length - 1].title = "Tiền mặt 100.000";
                DataReward.cash.push(resp.cash["Tiền mặt 50.000"]);
                DataReward.cash[DataReward.cash.length - 1].title = "Tiền mặt 50.000";
                DataReward.cash.push(resp.cash["Tiền mặt 30.000"]);
                DataReward.cash[DataReward.cash.length - 1].title = "Tiền mặt 30.000";
                DataReward.cash.push(resp.cash["Tiền mặt 20.000"]);
                DataReward.cash[DataReward.cash.length - 1].title = "Tiền mặt 20.000";
                DataReward.cash.push(resp.cash["Tiền mặt 10.000"]);
                DataReward.cash[DataReward.cash.length - 1].title = "Tiền mặt 10.000";

                DataReward.topup.push(resp.topup["Voucher Topup 500.000"]);
                DataReward.topup[DataReward.topup.length - 1].title = "Voucher 500.000";
                DataReward.topup.push(resp.topup["Voucher Topup 200.000"]);
                DataReward.topup[DataReward.topup.length - 1].title = "Voucher 200.000";
                DataReward.topup.push(resp.topup["Voucher Topup 100.000"]);
                DataReward.topup[DataReward.topup.length - 1].title = "Voucher 100.000";
                DataReward.topup.push(resp.topup["Voucher Topup 50.000"]);
                DataReward.topup[DataReward.topup.length - 1].title = "Voucher 50.000";
                DataReward.topup.push(resp.topup["Voucher Topup 30.000"]);
                DataReward.topup[DataReward.topup.length - 1].title = "Voucher 30.000";
                DataReward.topup.push(resp.topup["Voucher Topup 20.000"]);
                DataReward.topup[DataReward.topup.length - 1].title = "Voucher 20.000";
                DataReward.topup.push(resp.topup["Voucher Topup 10.000"]);
                DataReward.topup[DataReward.topup.length - 1].title = "Voucher 10.000";

                DataReward.voucherGotit.KFC = resp.voucherGotit.KFC;
                DataReward.voucherGotit.Galaxy_Play = resp.voucherGotit.Galaxy_Play;
                DataReward.voucherGotit.California_Fitness = resp.voucherGotit.California_Fitness;

                SetDataReward(DataReward);
            });
    }, [])



    return (
        <div className='relative flex justify-center z-[3]' style={{ background: "linear-gradient(176deg, #FFDCA4 6.67%, #FD954A 117.54%)" }}>
            <div className='absolute w-[100%] h-[100%] mt-[10%]'>
                <div className='flex flex-row justify-start ml-[-4%] w-[100%]'>
                    <img src={coin_2} className='w-[9%] h-[90%] ml-[4%] mt-[-20%] z-[2]'></img>
                </div>
                <div className='flex flex-row justify-end pr-[0%] w-[100%]'>
                    <img src={icon_spin} className='w-[34%] mt-[-20%] z-[2]'></img>
                </div>
                <div>
                    <img src={longcity} className='mix-blend-overlay opacity-80 w-[84%] mt-[-10%] z-[2]'></img>
                </div>
                <img src={ticket_plane} className='w-[8%] mt-[20%]'></img>
            </div>
            <div className='flex flex-col items-center justify-center'>
                <div className='min-[1403px]:w-[1403px] max-[1403px]:w-[100%] flex flex-col items-center justify-center'>
                    <div className='flex flex-col sm:mt-[4%] max-sm:mt-[10%] items-center'>
                        <img id='giaithuong' src={pize_title} className='sm:w-[37%] max-sm:w-[60%]'></img>
                        <img src={spin_number} className='sm:w-[35%] max-sm:w-[55%] sm:mt-[-1%] max-sm:mt-[-3%]'></img>
                        <div className='relative w-[100%] flex justify-center'>
                            <img src={bg_fire_reward} className='max-sm:hidden mt-[-20%] absolute w-[100%] mix-blend-screen'></img>
                            <img src={bg_fire_reward_mobile} className='sm:hidden mt-[-25%] absolute w-[100%] mix-blend-screen'></img>
                            <img src={prizes_image} className='max-sm:hidden w-[100%] z-[2]'></img>
                            <img src={prizes_image_mobile} className='sm:hidden w-[80%] z-[2]'></img>
                        </div>
                        {linkReward === "" ?
                            <div onClick={() => ChangeTextReward()} className='mt-[2%] flex justify-center cursor-pointer'>
                                <img src={time_button} className='z-[2] sm:w-[40%] max-sm:w-[70%]'></img>
                            </div> :
                            <HashLink target='_blank' to={linkReward} className='mt-[2%] flex justify-center cursor-pointer'>
                                <img src={time_button} className='z-[2] w-[40%] max-sm:w-[70%]'></img>
                            </HashLink>
                        }
                        <img src={line_reward} className='mt-[-3%] sm:w-[52%] max-sm:w-[72%]'></img>
                        <img src={store_prizes} className='sm:w-[25%] mt-[-2%] max-sm:w-[40%]'></img>
                    </div>
                    <div className='flex sm:flex-row z-[2] max-sm:flex-col max-sm:items-center sm:justify-between'>
                        <div className='flex sm:w-[100%] max-sm:w-[80%] flex-col'>
                            <div className='relative grow-1'>
                                <div className='absolute pl-[3%] flex w-[95%] flex-row h-[100%] justify-between items-center'>
                                    <img src={text_cash} className='ml-[5%] w-[57%]'></img>
                                    <img src={coin} className='w-[26%] mb-[3%]'></img>
                                </div>
                                <img src={bg_title_prize} className='w-[95%]'></img>
                            </div>
                            <div>
                                <div className='relative w-[95%]'>
                                    <div className='absolute h-[100%] px-[5%] py-[8%] w-[100%]'>
                                        <div className='leading-auto 2xl:text-[14] xl:text-[12px] lg:text-[10px] md:text-[8px] sm:text-[8px] max-sm:text-[6px] text-[#162B75] montserrat-bold flex justify-around flex-row'>
                                            <div className='w-[60%]'>Quà tặng</div>
                                            <div className='w-[50%]'>Số lượng còn lại</div>
                                        </div>
                                        <div className='flex flex-col py-[3%] h-[100%] justify-around'>
                                            {dataReward.cash.map((item, index) => <div className='pl-[2%] relative flex flex-row justify-center'>
                                                <img src={(index % 2) === 0 ? line : line_white} className='w-[90%]'></img>
                                                <div className='absolute flex w-[100%] leading-auto 2xl:text-[16] xl:text-[14px] lg:text-[12px] mt-[1%] md:text-[10px] sm:text-[10px] montserrat-bold max-sm:text-[8px]'>
                                                    <div className='pl-[10%] w-[50%] text-start text-[#D25F2B]'>{item.title}</div>
                                                    <div className='text-[#162B75] w-[50%]'>{item.unclaimed}/{item.initialAmount}</div>
                                                </div>
                                            </div>)}
                                        </div>
                                    </div>
                                    <img src={frame}></img>
                                </div>
                            </div>
                        </div>
                        <div className='flex sm:w-[100%] max-sm:w-[80%] flex-col'>
                            <div className='relative grow-1'>
                                <div className='absolute pl-[3%] flex w-[95%] flex-row h-[100%] justify-between items-center'>
                                    <img src={text_vourcher} className='ml-[5%] w-[57%]'></img>
                                    <img src={image_voucher} className='w-[30%] mb-[3%]'></img>
                                </div>
                                <img src={bg_title_prize} className='w-[95%]'></img>
                            </div>
                            <div>
                                <div className='relative w-[95%]'>
                                    <div className='absolute h-[100%] px-[5%] py-[8%] w-[100%]'>
                                        <div className='leading-auto 2xl:text-[14] xl:text-[12px] lg:text-[10px] md:text-[8px] sm:text-[8px] max-sm:text-[6px] text-[#162B75] montserrat-bold flex justify-around flex-row'>
                                            <div className='w-[60%]'>Quà tặng</div>
                                            <div className='w-[50%]'>Số lượng còn lại</div>
                                        </div>
                                        <div className='flex flex-col py-[3%] h-[100%] justify-around'>
                                            {dataReward.topup.map((item, index) => <div className='pl-[2%] relative flex flex-row justify-center'>
                                                <img src={(index % 2) === 0 ? line : line_white} className='w-[90%]'></img>
                                                <div className='absolute flex w-[100%] leading-auto 2xl:text-[16] xl:text-[14px] lg:text-[12px] mt-[1%] md:text-[10px] sm:text-[10px] montserrat-bold max-sm:text-[8px]'>
                                                    <div className='w-[50%] text-start pl-[10%] text-[#D25F2B]'>{item.title}</div>
                                                    <div className='w-[50%] text-[#162B75]'>{item.unclaimed}/{item.initialAmount}</div>
                                                </div>
                                            </div>)}
                                        </div>
                                    </div>
                                    <img src={frame}></img>
                                </div>
                            </div>
                        </div>
                        <div className='flex sm:w-[100%] max-sm:w-[80%] flex-col'>
                            <div className='relative grow-1'>
                                <div className='absolute pl-[6%] flex w-[95%] flex-row h-[100%] justify-between items-center'>
                                    <img src={text_gotit} className='w-[45%] ml-[9%]'></img>
                                    <img src={image_gotit} className='w-[26%] mb-[3%]'></img>
                                </div>
                                <img src={bg_title_prize} className='w-[95%]'></img>
                            </div>
                            <div>
                                <div className='relative w-[95%]'>
                                    <div className='absolute h-[100%] px-[5%] py-[8%] w-[100%]'>
                                        <div className='flex flex-col py-[3%] h-[100%] justify-around'>
                                            <div className='pl-[2%] relative flex flex-row justify-center'>
                                                <img src={galaxy} className='w-[90%]'></img>
                                                <div className='absolute mt-[-1%] flex items-center h-[100%] flex-col justify-center w-[100%] leading-auto 2xl:text-[16] xl:text-[14px] montserrat-bold mt-[1%] lg:text-[12px] md:text-[10px] sm:text-[10px] max-sm:text-[8px] flex-row'>
                                                    <div className='text-[#D25F2B] pl-[15%]'>Gói Galaxy Play Mobile 3 tháng</div>
                                                    <div className='text-[#162B75] pl-[15%]'>Số lượng còn lại: {dataReward.voucherGotit.Galaxy_Play.unclaimed}/{dataReward.voucherGotit.Galaxy_Play.initialAmount}</div>
                                                </div>
                                            </div>
                                            <div className='pl-[2%] relative flex flex-row justify-center'>
                                                <img src={canifornia} className='w-[90%]'></img>
                                                <div className='absolute mt-[-1%] flex items-center h-[100%] flex-col justify-center w-[100%] leading-auto 2xl:text-[16] xl:text-[14px] montserrat-bold mt-[1%] lg:text-[12px] md:text-[10px] sm:text-[10px] max-sm:text-[8px] flex-row'>
                                                    <div className='text-[#D25F2B] pl-[15%]'>Miễn phí tập luyện tại California</div>
                                                    <div className='text-[#162B75] pl-[15%]'>Số lượng còn lại: {dataReward.voucherGotit.California_Fitness.unclaimed}/{dataReward.voucherGotit.California_Fitness.initialAmount}</div>
                                                </div>
                                            </div>
                                            <div className='pl-[2%] relative flex flex-row justify-center'>
                                                <img src={kfc} className='w-[90%]'></img>
                                                <div className='absolute mt-[-1%] flex items-center h-[100%] flex-col justify-center w-[100%] leading-auto 2xl:text-[16] xl:text-[14px] montserrat-bold mt-[1%] lg:text-[12px] md:text-[10px] sm:text-[10px] max-sm:text-[8px] flex-row'>
                                                    <div className='text-[#D25F2B] pl-[15%]'>Voucher giảm 30K tại KFC</div>
                                                    <div className='text-[#162B75] pl-[15%]'>Số lượng còn lại: {dataReward.voucherGotit.KFC.initialAmount === 0 ? "4000/4000" : dataReward.voucherGotit.KFC.initialAmount == null ? "4000/4000" : dataReward.voucherGotit.KFC.unclaimed.toString() + "/" + dataReward.voucherGotit.KFC.initialAmount.toString()}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <img src={frame}></img>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Reward