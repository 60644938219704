
import map from '../images/map.png'
import bg_book from '../images/bg_book.png'
import arrow from '../images/arrow_header.png'
import hanhtrinh from '../images/hanh_trinh_30_nam.png'
import firework_big from '../images/firework_big.png'
import fire_little from '../images/fire_little.png'
import firework_small from '../images/firework_small.png'
import ha_noi from '../images/ha_noi.png'
import can_tho from '../images/can_tho.png'
import map_box_active from '../images/map_box_active.png'
import map_plane_active from '../images/map_plane_active.png'
import map_building_active from '../images/map_building_active.png'
import map_soccer_active from '../images/map_soccer_active.png'
import map_building2_active from '../images/map_building2_active.png'
import map_airport_active from '../images/map_airport_active.png'
import book_tam from '../images/book_tam.png'
import book_tin from '../images/book_tin.png'
import book_tins from '../images/book_tins.png'
import book_tri from '../images/book_tri.png'
import book_tris from '../images/book_tris.png'
import book_tamf from '../images/book_tamf.png'
import right from '../images/right.png'
import right_end from '../images/right_end.png'
import left from '../images/left.png'
import left_end from '../images/left_end.png'
import { useEffect, useState } from 'react'
import infor_contact from '../images/infor_contact.png'
import { HashLink } from 'react-router-hash-link'
import Lottie from 'lottie-react'
import Light from "../animations/Light.json"

interface book {
  image: string,
  year: string,
  ideal: string,
  nameClass?: string
}
const dataBooks: book[] = [{
  image: book_tam,
  year: "1993-2007",
  ideal: "Lấy TÂM làm gốc, kể từ khi thành lập vào năm 1993, SHB đã luôn dành trọn TÂM cống hiến cho khách hàng. Hãy trải nghiệm “Trọn Tâm khai mở” để khám phá công việc dựng xây những nền móng ban đầu của SHB bạn nhé!",
  nameClass: "absolute z-[1] w-[100%] sm:ml-[25%] sm:mt-[106%] max-sm:ml-[25%] max-sm:mt-[106%]"
},
{
  image: book_tin,
  year: "2007-2012",
  ideal: "Là hành trình cố gắng không ngừng, SHB từng bước tạo dựng được lòng TIN từ khách hàng. Năm 2008, SHB chuyển trụ sở từ Cần Thơ về Hà Nội. Hãy sải cánh “Tiến về thủ đô” cùng SHB nào!",
  nameClass: "absolute z-[1] sm:ml-[-28%] sm:mt-[40%] max-sm:ml-[-28%] max-sm:mt-[40%]"
},
{
  image: book_tins,
  year: "2012-2017",
  ideal: "Bền bỉ và cam kết, SHB dần khẳng định được uy TÍN trên thị trường. Năm 2012, SHB mở chi nhánh tại Lào và Campuchia. Cùng SHB “Vươn ra khu vực” bạn nhé!",
  nameClass: "absolute w-[100%] z-[1] sm:ml-[35%] sm:mt-[35%] max-sm:ml-[35%] max-sm:mt-[35%]"
},
{
  image: book_tri,
  year: "2017-2019",
  ideal: "SHB luôn nỗ lực trau đồi TRI thức, “Hoàn thiện bản thân”, mở rộng hợp tác với các đối tác lớn trong nước và trên thế giới, trong đó có Câu lạc bộ bóng đá Barcelona. Bạn có muốn trở thành cầu thủ chuyên nghiệp đẳng cấp thế giới?",
  nameClass: "w-[100%] absolute z-[1] sm:ml-[-20%] sm:mt-[10%] max-sm:ml-[-20%] max-sm:mt-[10%]"
},
{
  image: book_tris,
  year: "2019-2023",
  ideal: "Bước vào kỷ nguyên số, SHB đã đầu tư TRÍ lực, nhân lực, áp dụng các nền tảng công nghệ số hiện đại, mang đến những sản phẩm số ưu việt với trải nghiệm mượt mà, an tâm. Bạn đã sẵn sàng chinh phục “Thời đại số” cùng SHB chưa?",
  nameClass: "absolute w-[100%] z-[1] sm:ml-[30%] max-sm:ml-[30%] sm:mt-[-10%] max-sm:mt-[-10%]"
},
{
  image: book_tamf,
  year: "2023-tương lai",
  ideal: "Trải qua chặng đường 30 năm, SHB đã từ TÂM vươn TẦM, trở thành 1 trong 5 Ngân hàng TMCP tư nhân có quy mô lớn nhất Việt Nam. Cùng thổi nến, cắt bánh và chúc mừng sinh nhật SHB bạn nhé!",
  nameClass: "absolute z-[1] sm:ml-[-40%] sm:mt-[-50%] max-sm:ml-[-40%] max-sm:mt-[-60%]"
},
]

const Journey = () => {
  const [indexActive, SetIndexActive] = useState(0);
  const [isLoadMap, SetIsLoadMap] = useState(true);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Light,
  };

  useEffect(() => {
    if (!isLoadMap) {
      return;
    }
    const LoadMap = setInterval(() => {
      if (!isLoadMap) {
        return;
      }
      if ((indexActive + 1) >= 6) {
        SetIndexActive(0);
      } else {
        SetIndexActive(indexActive + 1);
      }
      clearInterval(LoadMap);
    }, 7000);
  });

  const NextBook = () => {
    SetIsLoadMap(false);
    if ((indexActive + 1) >= dataBooks.length) {
      return;
    }
    SetIndexActive(indexActive + 1)
  };

  const BackBook = () => {
    SetIsLoadMap(false);
    if ((indexActive - 1) < 0) {
      return;
    }
    SetIndexActive(indexActive - 1)
  };

  const LoadMap = (index: number) => {
    SetIsLoadMap(false);
    SetIndexActive(index);
  }

  return (
    <div className='relative flex justify-center' style={window.innerWidth > 400 ? { background: "linear-gradient(181deg, #FDCA62 1.77%, #F6AA48 26.19%, #FFA05B 53.55%, rgba(255, 157, 74, 0.69) 82.79%, rgba(255, 227, 185, 0.28) 107.89%)" } : { background: "linear-gradient(181deg, #F9D56C 1.77%, #F6AA48 26.19%, #FFA05B 53.55%, rgba(255, 157, 74, 0.69) 82.79%, rgba(255, 227, 185, 0.28) 107.89%)" }}>
      <div className='flex fixed bottom-[6%] max-sm:w-[40%] sm:w-[25%] 2xl:w-[25%] flex-col z-[5] items-end right-[4%]'>
        <HashLink smooth to={"/#top"} className='cursor-pointer max-sm:w-[16%] sm:w-[12%] lg:w-[12%] 2xl:w-[12%] flex '>
          <img src={arrow} className='w-[100%]'></img>
        </HashLink>
        <div className='relative flex max-sm:w-[90%] sm:w-[80%] 2xl:w-[70%] flex-row justify-end items-center'>
          <img src={infor_contact} className='mr-[-1.5%] mt-[1%]'></img>
          <HashLink target='_blank' className='absolute w-[100%] h-[70%]' to={"https://support.shb.com.vn/csportalclient/refimplementation/chat.html?fbLoginType=guest#"}>
            <img src={infor_contact} className='opacity-0 mr-[-1.5%] h-[90%]'></img>
          </HashLink>
        </div>
      </div>
      <div className='absolute w-[100%] h-[100%] mt-[10%]'>
        <div className='relative flex flex-row justify-end w-[100%]'>
          <img src={firework_big} className='mix-blend-overlay w-[13%] mr-[20%]'></img>
          <img src={fire_little} className='absolute mix-blend-screen w-[8%] mt-[3%] mr-[22%]'></img>
        </div>
        <img src={firework_small} className='mix-blend-overlay w-[8%] ml-[4%]'></img>
      </div>
      <div className='absolute w-[100%] h-[100%] max-sm:z-[2]'>
        <div className='flex flex-col mt-[25%] h-[100%] justify-end w-[100%]'>
          <div className='flex flex-row mix-blend-overlay'>
            <img src={ha_noi} className='w-[60%] opacity-60 max-sm:h-[52%] sm:h-[38%]'></img>
            <img src={can_tho} className='w-[60%] opacity-60 max-sm:h-[52%] h-[58%]'></img>
          </div>
        </div>
      </div>
      <div className='flex flex-col items-center justify-center'>
        <div className='min-[1403px]:w-[1403px] max-[1403px]:w-[100%] '>
          <div className='mt-[2.5%]'>
            <div className='text-[#162B75] montserrat-semibold leading-auto 2xl:text-[24] xl:text-[20px] lg:text-[16px] md:text-[12px] sm:text-[12px] max-sm:text-[12px]'>Thời gian diễn ra chương trình:</div>
            <div className='text-[#162B75] montserrat-extra-bold leading-auto 2xl:text-[28] xl:text-[24px] lg:text-[20px] md:text-[16px] sm:text-[14px] max-sm:text-[14px]'>20/10/2023 - 31/12/2023</div>
          </div>
          <div className='flex mt-[4%] justify-center'>
            <img id='hanhtrinh30nam' src={hanhtrinh} className='sm:w-[45%] max-sm:w-[65%] z-[2]'></img>
          </div>
          <div className='flex sm:flex-row max-sm:flex-col justify-between items-center mt-[3%]'>
            <div className='relative sm:w-[38%] max-sm:w-[64%] z-[3]'>
              <img src={map_box_active} onClick={() => LoadMap(5)} className={'absolute w-[38%]  z-[2] ml-[15%] mt-[-2%]'} style={indexActive == 5 ? {scale: "1.5"} : {scale: "1"}}></img>
              <div className='absolute flex justify-end mt-[20%] z-[2]'>
                <img src={map_plane_active} onClick={() => LoadMap(4)} className='w-[42%]' style={indexActive == 4 ? {scale: "1.5"} : {scale: "1"}}></img>
              </div>
              <img src={map_soccer_active} onClick={() => LoadMap(3)} className='absolute w-[46%] z-[2] ml-[10%] mt-[37%]' style={indexActive == 3 ? {scale: "1.5"} : {scale: "1"}}></img>
              <div className='absolute flex justify-end mt-[56%]'>
                <img src={map_building_active} onClick={() => LoadMap(2)} className='w-[42%] z-[2]' style={indexActive == 2 ? {scale: "1.5"} : {scale: "1"}}></img>
              </div>
              <img src={map_airport_active} onClick={() => LoadMap(1)} className='absolute w-[55%] z-[2] mt-[75%]' style={indexActive == 1 ? {scale: "1.5"} : {scale: "1"}}></img>
              <div className={dataBooks[indexActive].nameClass} style={{display: "none"}}>
                <Lottie animationData={Light} ></Lottie>
              </div>
              <img src={map_building2_active} onClick={() => LoadMap(0)} className='absolute z-[2] w-[45%] ml-[50%] mt-[120%]' style={indexActive == 0 ? {scale: "1.5"} : {scale: "1"}}></img>
              <img src={map}></img>
            </div>
            <div className='relative sm:w-[42.7%] max-sm:w-[64%] z-[3]'>
              <div className='absolute mx-[8%] my-[5%]'>
                <div className='mr-[18%] flex flex-col items-center'>
                  <img src={dataBooks[indexActive].image} className='w-[58%]'></img>
                  <div className='font-semibold mt-[5%] text-justify text-[#162B75] xl:text-[18px] lg:text-[16px] md:text-[14px] sm:text-[12px] max-sm:text-[11px]'>
                    {dataBooks[indexActive].ideal}
                  </div>
                </div>
              </div>
              <div className='absolute h-[100%] flex flex-col w-[100%] justify-end items-center'>
                <div className='flex flex-row mb-[8%] justify-center mr-[15%]' >
                  <img src={indexActive == 0 ? left_end : left} onClick={() => BackBook()} className='w-[14%] cursor-pointer mr-[2%]'></img>
                  <img src={indexActive == (dataBooks.length - 1) ? right_end : right} onClick={() => NextBook()} className='w-[14%] cursor-pointer'></img>
                </div>
              </div>
              <img src={bg_book}></img>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Journey