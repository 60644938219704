import React, { ReactNode } from "react";
import { useState } from "react";

interface IChildren {
    children: ReactNode
}

export interface IValue {
    isShowPopup: boolean;
    SetActivePopup?: (status: boolean) => void;
}

const defaultValue:IValue = {
    isShowPopup: false,
}
export const PopupContext = React.createContext(defaultValue);

export const PopupProvider = ({ children }: IChildren) => {
    const [isShowPopup, SetShowPopup] = useState<boolean>(false);
    const SetActivePopup = (status: boolean) => {
        SetShowPopup(status);
    };
    const value: IValue = {isShowPopup, SetActivePopup};
    return <PopupContext.Provider value={value}>{children}</PopupContext.Provider>;
};