import title_challenge from '../images/title_challenge.png'
import map_infor from '../images/map_infor.png'
import map_infor_title from '../images/map_infor_title.png'
import map_infor1 from '../images/map_infor1.png'
import map_infor2 from '../images/map_infor2.png'
import map_infor3 from '../images/map_infor_3.png'
import map_infor_mobile from '../images/map_infor_mobile.png'
import bg_button_open from '../images/bg_button_open.png'
import zalo from '../images/zalo.png'
import messenger from '../images/messenger.png'
import line_footer from '../images/line_footer.png'
import logo from '../images/logo.png'
import contact_web from '../images/contact_web.png'
import gmail from '../images/gmail.png'
import facebook from '../images/facebook.png'
import youtube from '../images/youtube.png'
import qr1 from '../images/qr1.png'
import qr2 from '../images/qr2.png'
import { LegacyRef, SetStateAction, useEffect, useState } from 'react'
import icon_accordion from '../images/icon_accordion.png'
import address from '../images/address.png'
import ball from '../images/ball.png'
import city_2 from '../images/city_2.png'
import ticket from '../images/ticket.png'
import title_payment from '../images/title_payment.png'
import detail_payment from '../images/detail_payment.png'
import moc_table from '../images/moc_table.png'
import { HashLink } from 'react-router-hash-link'
import React from 'react'

export interface question {
    title: string,
    description: string[],
}

const Challenge = () => {

    const imgEl1: LegacyRef<HTMLImageElement> | undefined = React.createRef();
    const imgEl2: LegacyRef<HTMLImageElement> | undefined = React.createRef();

    const DataQuestion: question[] = [
        {
            title: "Đối tượng khách hàng được tham gia chương trình?",
            description: ["Tất cả Khách hàng cá nhân tham gia sử dụng sản phẩm, dịch vụ của SHB thỏa mãn điều kiện, thể lệ của chương trình."]
        },
        {
            title: "Làm thế nào để thu thập lượt chơi Game?",
            description: ["Khách hàng đăng nhập ứng dụng của SHB, truy cập Game bằng cách nhấn vào biểu tượng Game/chọn phần Game ở Menu trái của ứng dụng. Tại màn hình giao diện chính của Game, khách hàng nhấn nút \"Nhiệm vụ\" để xem danh sách nhiệm vụ và lượt chơi tương ứng. Khách hàng hoàn thành nhiệm vụ sẽ nhận lượt chơi Game và có cơ hội trúng vô vàn giải thưởng giá trị của chương trình."]
        },
        {
            title: "Làm thế nào để biết tôi có bao nhiêu lượt chơi?",
            description: ["Khách hàng đăng nhập ứng dụng của SHB, truy cập Game bằng cách nhấn vào biểu tượng Game/chọn phần Game ở Menu trái của ứng dụng. Số lượt chơi của khách hàng sẽ hiển thị ngay trên màn hình giao diện chính của Game."]
        },
        {
            title: "Lượt chơi game của tôi có giới hạn không?",
            description: ["Mỗi khách hàng được nhận tối đa 50 lượt chơi/tuần. Lượt chơi không sử dụng hết trong tuần sẽ được cộng dồn cho tuần kế tiếp. Lượt chơi game sẽ có hiệu lực trong tháng tích lũy. Khách hàng sẽ phải tích lũy lại lượt chơi Game trong tháng kế tiếp. Sau khi chương trình kết thúc, lượt chơi của khách hàng có giá trị đến hết 07/1/2024."]
        },
        {
            title: "Làm thế nào để biết tôi đã trúng thưởng và tôi có thể kiểm tra các quà tặng đã trúng thưởng ở đâu?",
            description: ["Đối với Games trên ứng dụng SHB Mobile/SHB SAHA: SHB sẽ thông báo ngay trên màn hình khi khách hàng chơi game và trúng thưởng. Đồng thời các giải thưởng đã trúng sẽ được lưu vào \"Giỏ quà của bạn\" trong Game và khách hàng có thể sử dụng bất cứ khi nào theo nhu cầu. Danh sách khách hàng trúng thưởng sẽ được cập nhật liên tục theo kết quả thực tế và hiển thị công khai trên website của chương trình https://sinhnhattrian.shb.com.vn và website SHB https://www.shb.com.vn.",
                "Đối với chương trình quay số cuối kỳ: Trong vòng 07 ngày kể từ ngày có kết quả quay số trúng thưởng cuối kỳ, SHB sẽ thông báo kết quả trúng thưởng đến từng khách hàng bằng cách gọi điện thoại hoặc email, đồng thời thông báo công khai trên website SHB https://www.shb.com.vn và các điểm giao dịch của SHB."]
        },
        {
            title: "Khi nào tôi nhận được quà tặng và nhận qua đâu?",
            description: ["Với giải thưởng là Evoucher nạp tiền điện thoại trên ứng dụng SHB, SHB sẽ trả thưởng ngay khi khách hàng trúng quà, phần thưởng sẽ được hiển thị trong mục “Giỏ quà của bạn”.",
                "Với giải thưởng là tiền mặt, SHB sẽ tổng hợp giải thưởng và thực hiện chuyển khoản vào tài khoản đang hoạt động của khách hàng tại SHB. Trong trường hợp khách hàng chưa có tài khoản tại SHB, khách hàng cần thực hiện mở tài khoản thanh toán theo quy định của SHB. SHB sẽ hạch toán tiền thưởng cho khách hàng muộn nhất vào ngày 15/1/2024.",
                "Đối với giải thưởng là các eVoucher KFC, Galaxy Play, California, SHB sẽ trả thưởng ngay khi khách hàng trúng quà, mã Evoucher sẽ dược hiển thị ngay trên màn hình điện thoại của khách hàng, và được lưu trữ trong mục “Giỏ quà của tôi”. Khách hàng sử dụng trực tiếp các mã Evoucher tại các địa điểm được quy định theo từng mã Evoucher.",
                "Với giải thưởng quay số cuối chương trình: SHB sẽ tổ chức trao thưởng cho khách hàng trong vòng 45 ngày kể từ ngày kết thúc chương trình."]
        },
        {
            title: "Làm thế nào để tôi nhận được mã dự thưởng tham gia quay số cuối chương trình?",
            description: ["Khách hàng sử dụng các sản phẩm, dịch vụ của SHB đáp ứng được điều kiện, thể lệ của chương trình sẽ nhận được mã dự thưởng. Hoặc khách hàng hoàn thành các thử thách trong 6 chặng Game để thu thập đủ bộ 6 chữ Tâm - Tin - Tín - Tri - Trí - Tầm."]
        },
        {
            title: "	Nếu trúng giải tôi có cần chi trả chi phí gì không?",
            description: ["Khách hàng có trách nhiệm thực hiện nộp thuế TNCN theo quy định hiện hành (nếu có)."]
        },
    ]

    const [activeIndex, setActiveIndex] = useState(-1);
    const [heightImage, setHeightImage] = useState<string>("580px");
    const [widthImage, setWidthImage] = useState<string>("406px");

    const SetActiveIndex = (index: number) => {
        if (activeIndex === index) {
            setActiveIndex(-1);
        } else {
            setActiveIndex(index);
        }
    }

    return (
        <div className='relative flex flex-col max-sm:mt-[-0.1%] max-sm:pb-[12%] 2xl:pb-[0%] xl:pb-[3.5%] lg:pb-[5%] md:pb-[9%] sm:pb-[8.5%]' style={{ background: "linear-gradient(3deg, #F37221 3.21%, #FE9E56 38.03%)" }}>
            <div className='absolute w-[100%] h-[100%]'>
                <div className='flex flex-row justify-start ml-[-4%] w-[100%]'>
                    <img src={address} className='mix-blend-overlay w-[100%] ml-[4%] opacity-80 mt-[-8%] z-[2]'></img>
                </div>
                <div className='w-[100%] flex justify-end'>
                    <img id='test' src={ball} className='w-[20%] opacity-40'></img>
                </div>
                <img src={city_2} className='mix-blend-overlay mt-[10%] w-[100%] opacity-80'></img>
                <img src={ticket} className='w-[9%] ml-[5%] z-[-1]'></img>
            </div>
            <div className='flex flex-col items-center z-[3] justify-center'>
                <div className='min-[1403px]:w-[1403px] max-[1403px]:w-[100%] flex flex-col items-center justify-center'>
                    <HashLink to={"https://app.adjust.com/163624zw"} className='relative w-[100%] z-[3] cursor-pointer flex justify-center items-center mt-[3%] max-sm:mt-[6%]'>
                        <img src={bg_button_open} className='cursor-pointer sm:w-[20%] max-sm:w-[40%]'></img>
                    </HashLink>
                    <img id='khamphathuthach' src={title_challenge} className='sm:w-[46%] max-sm:w-[60%] sm:mt-[0.5%] max-sm:mt-[4%]' ></img>
                    <div className='max-sm:hidden items-center w-[100%] mt-[0.5%] justify-center flex flex-col'>
                        <img src={map_infor_title} className='w-[29%]'></img>
                        <div className='flex mt-[1.5%] justify-center'>
                            <img src={map_infor1} className='w-[29%] h-[100%]'></img>
                            <img src={map_infor2} ref={imgEl1} onLoad={() => {
                                const height = (imgEl1.current?.height === undefined ? 600 : imgEl1.current?.height) - (imgEl2.current?.height === undefined ? 100 : imgEl2.current?.height);  
                                setHeightImage((height + "px").toString());
                                setWidthImage((imgEl1.current?.width === undefined ? "406" : imgEl1.current?.width.toString()) + "px");
                            }} className='w-[29%] h-[100%]'></img>
                            <div className='relative flex flex-col w-[29%] h-[100%]'>
                                <img src={title_payment} ref={imgEl2}></img>
                                <div className='flex absolute mt-[28.5%] justify-center w-[100%]'>
                                    <img src={moc_table} className='w-[75%]'></img>
                                </div>
                                <div className='w-[103%] overflow-auto justify-end items-end scroll-smooth scroll-auto w-[100%]' style={{ height: heightImage }}>
                                    <img src={detail_payment} className='w-[100%]' style={{width: widthImage}}></img>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='sm:hidden overflow-y-scroll mt-[5%] max-sm:h-[500px] w-[70%]'>
                        <img src={map_infor_mobile}></img>
                    </div>
                    <HashLink to={"https://app.adjust.com/163624zw"} className='relative cursor-pointer flex w-[100%] z-[3] justify-center items-center '>
                        <img src={bg_button_open} className='cursor-pointer mt-[4%] z-[3] sm:w-[20%] max-sm:w-[40%] max-sm:mt-[6%]'></img>
                    </HashLink>
                    <div className='flex flex-col mt-[4%] max-sm:mt-[7%] items-center justify-center'>
                        <div className='text-[#162B75] montserrat-extra-bold leading-auto 2xl:text-[48px] xl:text-[44px] lg:text-[40px] md:text-[36px] sm:text-[28px] max-sm:text-[20px]'>NHỮNG CÂU HỎI THƯỜNG GẶP</div>
                    </div>
                    <div className='flex sm:flex-row max-sm:flex-col max-sm:items-center items-start mt-[1.5%] w-[100%] flex-wrap'>
                        {DataQuestion.map((DataQuestion, index) =>
                            <>
                                <div className='sm:w-[50%] max-sm:w-[80%] h-[100%] items-start flex-col px-[5px] py-[10px]'>
                                    <div className='flex flex-row justify-between py-[3.5%] justify-center items-center px-[4.5%] montserrat-bold' style={{ background: "linear-gradient(176deg, #FBFCFD 3.33%, #DFE4EB 88.77%)", borderRadius: "13.713px", border: "2.743px solid rgba(255, 255, 255, 0.61)" }}>
                                        <div className='text-[#162B75] 2xl:h-[62px] xl:h-[42px] lg:h-[32px] md:h-[50px] sm:h-[52px] max-sm:h-[55px] leading-auto 2xl:text-[21px] montserrat-bold xl:text-[16px] text-start lg:text-[13px] md:text-[13px] sm:text-[13px] max-sm:text-[13px]'>
                                            {DataQuestion.title}
                                        </div>
                                        <img src={icon_accordion} className='cursor-pointer sm:w-[5%] sm:h-[10%] max-sm:w-[7%] max-sm:h-[12%]' onClick={() => SetActiveIndex(index)}></img>
                                    </div>
                                    {activeIndex !== index ? null : <div className='text-[#162B75] montserrat-regular leading-auto 2xl:text-[20px] py-[1.5%] max-sm:py-[3%] max-sm:px-[6%] mt-[23px] justify-center px-[4.5%] xl:text-[16px] text-start lg:text-[12px] md:text-[12px] sm:text-[12px] max-sm:text-[12px]' style={{ background: "rgba(255, 255, 255, 0.77)", borderRadius: "13.713px" }}>{DataQuestion.description.map((itemDescript) => <div>&#x2022; {itemDescript}</div>)}</div>}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
            <div className='w-[100%] z-[2] flex px-[2%]'>
                <div className='flex w-[25%]'>
                    <div className='flex flex-col'>
                        <HashLink target='_blank' className='sm:w-[28%] max-sm:w-[45%]' to={"https://zalo.me/shbofficial"}>
                            <img src={zalo}></img>
                        </HashLink>
                        <HashLink target='_blank' className='sm:w-[28%] max-sm:w-[45%]' to={"https://m.me/fanpageshb"}>
                            <img src={messenger}></img>
                        </HashLink>
                    </div>
                </div>
                <div className='relative flex items-center w-[50%] flex-col justify-center'>
                    <HashLink target='_blank' to={"https://www.shb.com.vn/wp-content/uploads/2023/10/The-le-chuong-trinh-Sinh-nhat-tri-an-Qua-tang-tu-tam.pdf"} className='cursor-pointer absolute underline text-[#173176] mt-[-3%] montserrat-bold leading-auto 2xl:text-[24px] xl:text-[20px] lg:text-[16px] md:text-[14px] sm:text-[12px] max-sm:text-[12px]'>THỂ LỆ CHI TIẾT CHƯƠNG TRÌNH XEM TẠI ĐÂY</HashLink>
                    <img src={line_footer} className='w-[100%]'></img>
                </div>
            </div>
            <div className='flex flex-col max-sm:hidden z-[2] items-center justify-center bg-[#FFF1D2] mb-[6%]'>
                <div className='min-[1403px]:w-[1403px] max-[1403px]:w-[100%] flex flex-row items-center justify-center'>
                    <div className='flex w-[100%] items-center flex-row py-[2%]'>
                        <img src={logo} className='w-[15%]'></img>
                        <div className='flex flex-row items-center justify-end '>
                            <HashLink target='_blank' className='w-[16%] mt-[0.8%] mr-[4%]' to={"tel://*6688"}>
                                <img src={contact_web} className='w-[100%]'></img>
                            </HashLink>
                            <HashLink target='_blank' className='w-[12%] mr-[2%] mt-[0.5%]' to={"https://mail.google.com/mail/u/0/#inbox?compose=CllgCJZfSZKnFqktQBKShmhbLjRTtQWGkVdxzfPXKVZClMWZNLMVWtBXdxRvnkcmRQHWmkRLXXq"}>
                                <img src={gmail} className='w-[100%]'></img>
                            </HashLink>
                            <HashLink target='_blank' className='w-[7%] mr-[2%] mt-[1%]' to={"https://www.facebook.com/fanpageshb"}>
                                <img src={facebook} className='w-[100%]'></img>
                            </HashLink>
                            <HashLink target='_blank' className='w-[6%] mr-[4%] mt-[1%]' to={"https://www.youtube.com/@nganhangshb3797"}>
                                <img src={youtube} className='w-[100%]'></img>
                            </HashLink>
                            <img src={qr1} className='w-[14%] h-[100%] mt-[0.3%]'></img>
                            <img src={qr2} className='ml-[2%] w-[14%] h-[100%]'></img>
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex flex-col sm:hidden z-[2] items-center justify-center 2xl:mb-[7%]  mb-[9%] bg-[#FFF1D2]'>
                <div className='min-[1403px]:w-[1403px] max-[1403px]:w-[100%] flex flex-row items-center justify-center'>
                    <div className='flex w-[100%] items-center flex-col px-[5%] py-[8%]'>
                        <div className='flex flex-row justify-between w-[100%]'>
                            <img src={logo} className='w-[48%]'></img>
                            <HashLink target='_blank' className='w-[35%]' to={"tel://*6688"}>
                                <img src={contact_web} className='w-[100%]'></img>
                            </HashLink>
                        </div>
                        <div className='flex my-[5%] flex-row justify-between w-[100%]'>
                            <HashLink target='_blank' className='w-[30%]' to={"https://mail.google.com/mail/u/0/#inbox?compose=CllgCJZfSZKnFqktQBKShmhbLjRTtQWGkVdxzfPXKVZClMWZNLMVWtBXdxRvnkcmRQHWmkRLXXq"}>
                                <img src={gmail} className='w-[100%]'></img>
                            </HashLink>
                            <HashLink target='_blank' className='w-[18%] mt-[0.5%]' to={"https://www.facebook.com/fanpageshb"}>
                                <img src={facebook} className='w-[100%]'></img>
                            </HashLink>
                            <HashLink target='_blank' className='w-[17%]' to={"https://www.youtube.com/@nganhangshb3797"}>
                                <img src={youtube} className='w-[100%]'></img>
                            </HashLink>
                        </div>
                        <div className='flex w-[100%] justify-between '>
                            <img src={qr1} className='w-[40%] h-[100%]'></img>
                            <img src={qr2} className='w-[40%] h-[100%]'></img>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Challenge