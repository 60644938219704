import bg_button_open from '../images/bg_button_open.png'
import buoc1 from '../images/buoc1.png'
import buoc2 from '../images/buoc2.png'
import buoc3 from '../images/buoc3.png'
import buoc4 from '../images/buoc4.png'
import firework_small from '../images/firework_small.png'
import participate from '../images/participate.png'
import coin_1 from '../images/coin_1.png'
import { HashLink } from 'react-router-hash-link'

const Prizes = () => {
    return (
        <div className='relative flex justify-center max-sm:mt-[-40%] max-sm:pt-[40%] mt-[-12%] z-[2]' style={{ background: "linear-gradient(178deg, rgba(255, 188, 68, 0.00) -1.79%, rgba(255, 167, 95, 0.00) -1.79%, #FFA05B 16.18%, #FFDBA4 66.56%)"}}>
            <div className='absolute w-[100%] h-[100%] mt-[10%]'>
                <div className='flex flex-row justify-start ml-[10%] mt-[15%]'>
                    <img src={firework_small} className='mix-blend-overlay w-[8%] ml-[4%]'></img>
                </div>
                <div className='flex flex-row justify-end pr-[3%] mt-[-15%] w-[100%]'>
                    <img src={coin_1} className='w-[13%]'></img>
                </div>
            </div>
            <div className='flex flex-col items-center justify-center mt-[8%]'>
                <div className='min-[1403px]:w-[1403px] max-[1403px]:w-[100%] flex flex-col justify-center'>
                    <div className='flex justify-center sm:mt-[6%] max-sm:mt-[1%]'>
                        <img id='cachthucthamgia' src={participate} className='sm:w-[25%] max-sm:w-[45%]'></img>
                    </div>
                    <div className='flex w-[100%] max-sm:hidden justify-center mt-[1%] sm:flex-row'>
                        <div className='relative flex grow-[2] justify-center items-end'>
                            <img src={buoc1} className='w-[85%]'></img>
                            <div className='w-[100%] h-[26%] absolute'>
                                <div className='leading-auto montserrat-bold xl:text-[20px] lg:text-[16px] md:text-[14px] max-md:text-[14px] mt-[5%] text-[#162B75]'>Bước 1:</div>
                                <div className='leading-auto montserrat-medium xl:text-[16px] lg:text-[14px] md:text-[12px] max-md:text-[12px] text-[#162B75]'>Đăng nhập ứng dụng</div>
                                <div className='leading-auto montserrat-medium xl:text-[16px] lg:text-[14px] md:text-[12px] max-md:text-[12px] text-[#162B75]'>SHB Mobile</div>
                            </div>
                        </div>
                        <div className='relative flex grow-[2] justify-center items-end'>
                            <img src={buoc2} className='w-[85%]'></img>
                            <div className='w-[100%] h-[26%] absolute'>
                                <div className='leading-auto montserrat-bold xl:text-[20px] lg:text-[16px] md:text-[14px] max-md:text-[14px] mt-[5%] text-[#162B75]'>Bước 2:</div>
                                <div className='leading-auto montserrat-medium xl:text-[16px] lg:text-[14px] md:text-[12px] max-md:text-[12px] text-[#162B75]'>Bấm vào Icon Sinh nhật</div>
                                <div className='leading-auto montserrat-medium xl:text-[16px] lg:text-[14px] md:text-[12px] max-md:text-[12px] text-[#162B75]'>tri ân trên màn hình </div>
                            </div>
                        </div>
                        <div className='relative flex grow-[2] justify-center items-end'>
                            <img src={buoc3} className='w-[85%]'></img>
                            <div className='w-[100%] h-[26%] absolute'>
                                <div className='leading-auto montserrat-bold xl:text-[20px] lg:text-[16px] md:text-[14px] max-md:text-[14px] mt-[5%] text-[#162B75]'>Bước 3:</div>
                                <div className='leading-auto montserrat-medium xl:text-[16px] lg:text-[14px] md:text-[12px] max-md:text-[12px] text-[#162B75]'>Thực hiện các nhiệm vụ</div>
                                <div className='leading-auto montserrat-medium xl:text-[16px] lg:text-[14px] md:text-[12px] max-md:text-[12px] text-[#162B75]'>để thu thập lượt chơi</div>
                            </div>
                        </div>
                        <div className='relative flex grow-[2] justify-center items-end'>
                            <img src={buoc4} className='w-[85%]'></img>
                            <div className='w-[100%] h-[26%] absolute'>
                                <div className='leading-auto  montserrat-bold xl:text-[20px] lg:text-[16px] md:text-[14px] max-md:text-[14px] mt-[5%] text-[#162B75]'>Bước 4:</div>
                                <div className='leading-auto montserrat-medium xl:text-[16px] lg:text-[14px] md:text-[12px] max-md:text-[12px] text-[#162B75] max-sm:leading-[1]'>Khám phá 6 chặng hành
                                </div>
                                <div className='leading-auto montserrat-medium xl:text-[16px] lg:text-[14px] md:text-[12px] max-md:text-[12px] text-[#162B75]'>trình <b>Từ Tâm vươn Tầm</b>
                                </div>
                                <div className='leading-auto montserrat-medium xl:text-[16px] lg:text-[14px] md:text-[12px] max-md:text-[12px] text-[#162B75]'>cùng SHB</div>
                            </div>
                        </div>
                    </div>
                    <div className='flex w-[100%] sm:hidden justify-center mt-[1%] flex-col'>
                        <div className='flex flex-row'>
                            <div className='relative flex grow-[2] justify-center items-end'>
                                <img src={buoc1} className='w-[100%]'></img>
                                <div className='w-[100%] h-[26%] absolute'>
                                    <div className='leading-auto montserrat-bold xl:text-[20px] lg:text-[16px] md:text-[14px] max-md:text-[14px] mt-[5%] text-[#162B75]'>Bước 1</div>
                                    <div className='leading-auto montserrat-medium xl:text-[16px] lg:text-[14px] md:text-[12px] max-md:text-[12px] text-[#162B75]'>Đăng nhập ứng dụng</div>
                                    <div className='leading-auto montserrat-medium xl:text-[16px] lg:text-[14px] md:text-[12px] max-md:text-[12px] text-[#162B75]'>SHB Mobile</div>
                                </div>
                            </div>
                            <div className='relative flex grow-[2] justify-center items-end'>
                                <img src={buoc2} className='w-[100%]'></img>
                                <div className='w-[100%] h-[26%] absolute'>
                                    <div className='leading-auto  montserrat-bold xl:text-[20px] lg:text-[16px] md:text-[14px] max-md:text-[14px] mt-[5%] text-[#162B75]'>Bước 2</div>
                                    <div className='leading-auto montserrat-medium xl:text-[16px] lg:text-[14px] md:text-[12px] max-md:text-[12px] text-[#162B75]'>Bấm vào Icon Sinh nhật</div>
                                    <div className='leading-auto montserrat-medium xl:text-[16px] lg:text-[14px] md:text-[12px] max-md:text-[12px] text-[#162B75]'>tri ân trên màn hình</div>
                                </div>
                            </div>
                        </div>

                        <div className='flex flex-row'>
                            <div className='relative flex grow-[2] justify-center items-end'>
                                <img src={buoc3} className='w-[100%]'></img>
                                <div className='w-[100%] h-[26%] absolute'>
                                    <div className='leading-auto  montserrat-bold xl:text-[20px] lg:text-[16px] md:text-[14px] max-md:text-[14px] mt-[5%] text-[#162B75]'>Bước 3</div>
                                    <div className='leading-auto montserrat-medium xl:text-[16px] lg:text-[14px] md:text-[12px] max-md:text-[12px] text-[#162B75]'>Thực hiện các nhiệm vụ</div>
                                    <div className='leading-auto montserrat-medium xl:text-[16px] lg:text-[14px] md:text-[12px] max-md:text-[12px] text-[#162B75]'>tham gia chơi</div>
                                </div>
                            </div>
                            <div className='relative flex grow-[2] justify-center items-end'>
                                <img src={buoc4} className='w-[100%]'></img>
                                <div className='w-[100%] h-[26%] absolute'>
                                    <div className='leading-auto  montserrat-bold xl:text-[20px] lg:text-[16px] md:text-[14px] max-md:text-[14px] mt-[5%] text-[#162B75]'>Bước 4</div>
                                    <div className='leading-auto montserrat-medium xl:text-[16px] lg:text-[14px] md:text-[12px] max-md:text-[12px] text-[#162B75]'>Khám phá 6 chặng hành
                                    </div>
                                    <div className='leading-auto montserrat-medium xl:text-[16px] lg:text-[14px] md:text-[12px] max-md:text-[12px] text-[#162B75]'>trình <b>Từ Tâm vươn Tầm</b>
                                    </div>
                                    <div className='leading-auto montserrat-medium xl:text-[16px] lg:text-[14px] md:text-[12px] max-md:text-[12px] text-[#162B75]'>cùng SHB
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <HashLink to={"https://app.adjust.com/163624zw"} className='relative flex justify-center items-center mt-[4%] max-sm:mt-[8%]'>
                        <img src={bg_button_open} className='cursor-pointer sm:w-[20%] max-sm:w-[40%]'></img>
                    </HashLink>
                </div>
            </div>
        </div>
    )
}

export default Prizes