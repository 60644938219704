import { useContext, useEffect } from 'react'
import Challenge from '../components/challenge'
import Header_content from '../components/header_content'
import Journey from '../components/journey30years'
import Infor_result_spin from '../components/popups/infor_result_spin'
import Prizes from '../components/prizes'
import Reward from '../components/reward'
import { IValue, PopupContext } from '../components/contexts/popupContext'

const Home = () => {

    const value: IValue = useContext(PopupContext);

    useEffect(() => {
        if (value.SetActivePopup !== undefined) {
            value.SetActivePopup(false);
        }
    }, []);

    const ClosePopup = () => {
        if (value.SetActivePopup !== undefined) {
            value.SetActivePopup(false);
        }
    }

    return (
        <>
            <div className='relative'>
                <div style={{ display: value.isShowPopup == false ? "none" : "block" }} className='fixed z-50 justify-center items-center w-[100%] h-[100%]'>
                    <Infor_result_spin onClick={ClosePopup}></Infor_result_spin>
                    <div className='w-[100%] z-[10] h-[100%] opacity-80 bg-black'></div>
                </div>
                <Header_content></Header_content>
                <Journey></Journey>
                <Prizes></Prizes>
                <Reward></Reward>
                <Challenge></Challenge>
            </div>

        </>
    )
}

export default Home