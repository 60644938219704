import { Route, Routes } from 'react-router';
import './App.css';
import Home from './screens/Home';
import { PopupProvider } from './components/contexts/popupContext';

function App() {
  return (
    <div className="App">
      <div className='relative flex justify-center w-[100%] '>
        <div className='w-[100%]'>
          <PopupProvider>
            <Routes>
              <Route path="/" Component={Home}>
              </Route>
            </Routes>
          </PopupProvider>
        </div>
      </div>
    </div>
  );
}

export default App;
