// import { getClientResolution } from './storage';

export const setStyles = () => {
    const UI_RATIO = 0.58;
    const CANVAS_RATIO = 428 / 926;
    const doc = document.documentElement;
    let clientWidth = doc.clientWidth;
    console.log(clientWidth);

    let clientHeight = doc.clientHeight;
    let paddingTop = 0;
    let paddingBottom = 0;

    const insetTop = getComputedStyle(doc).getPropertyValue('--inset-top');
    const insetBottom = getComputedStyle(doc).getPropertyValue('--inset-bottom');

    if (!isNaN(parseFloat(insetTop))) {
        paddingTop = parseFloat(insetTop);
    }

    if (!isNaN(parseFloat(insetBottom))) {
        paddingBottom = parseFloat(insetBottom);
    }

    // const resolution = getClientResolution();
    // if (typeof resolution.width === 'number') {
    //   clientWidth = resolution.width;
    // }
    // console.log(resolution.width);
    // if (typeof resolution.height === 'number') {
    //   clientHeight = resolution.height;
    // }
    // if (typeof resolution.paddingTop === 'number') {
    //   paddingTop = resolution.paddingTop;
    // }
    // if (typeof resolution.paddingBottom === 'number') {
    //   paddingBottom = resolution.paddingBottom;
    // }

    // if (
    //   typeof resolution.width !== 'number' &&
    //   typeof resolution.height !== 'number' &&
    //   (typeof resolution.paddingTop !== 'number' || typeof resolution.paddingBottom !== 'number')
    // ) {
    //   if (paddingTop) {
    //     clientHeight += paddingTop;
    //   }
    //   if (paddingBottom) {
    //     clientHeight += paddingBottom;
    //   }
    // }

    let gameWidth = 0;
    let gameHeight = 0;
    if (clientWidth / clientHeight >= UI_RATIO) {
        gameHeight = clientHeight;
        gameWidth = gameHeight * UI_RATIO;
    } else {
        gameWidth = clientWidth;
        gameHeight = gameWidth / UI_RATIO;
    }

    const paddingHorizontal = gameWidth * 0.025;
    let paddingHorizontalOffset = 0;
    let widthTmp = CANVAS_RATIO * clientHeight;
    if (widthTmp < gameWidth) {
        paddingHorizontalOffset = (gameWidth - widthTmp) / 2;
    } else {
        widthTmp = gameWidth;
    }
    const sectionWidth = gameWidth - paddingHorizontal * 2;
    doc.style.setProperty('--inset-top', `${paddingTop}px`);
    doc.style.setProperty('--inset-bottom', `${paddingBottom}px`);
    doc.style.setProperty('--app-height', `${clientHeight}px`);
    doc.style.setProperty('--app-width', `${clientWidth}px`);
    doc.style.setProperty('--game-width', `${gameWidth}px`);
    doc.style.setProperty('--game-height', `${gameHeight}px`);
    doc.style.setProperty('--game-width-content', `${widthTmp}px`);
    doc.style.setProperty('--padding-horizontal-offset', `${paddingHorizontalOffset}px`);
    doc.style.setProperty('--padding-horizontal', `${paddingHorizontal}px`);
    doc.style.setProperty('--section-width', `${sectionWidth}px`);
};
