import bg_popup from '../../images/bg_popup.png'
import title_popup from '../../images/title_popup.png'
import Btn_close from '../../images/Btn_close.png'
import detail_popup from '../../images/detail_popup.png'

const Infor_result_spin = (_props: { onClick: any }) => {
    return (
        <div className='absolute z-[20] items-center w-[100%] h-[100%] flex opacity-100 justify-center'>
            <div className='relative w-[42%] max-sm:w-[90%] max-md:w-[70%] max-lg:w-[60%] max-xl:w-[50%] max-2xl:w-[50%]'>
                <div className='absolute flex items-center flex-col'>
                    <img src={title_popup} className='mt-[-15%]'></img>
                    <div className='text-[#D97007] mt-[-3%] 2xl:text-[32px] xl:text-[24px] lg:text-[20px] md:text-[16px] sm:text-[12px] max-sm:text-[12px] montserrat-bold montserrat-bold'>Chương trình</div>
                    <img src={detail_popup} className='w-[72%]'></img>
                    <div className='text-[#D97007] 2xl:text-[32px] xl:text-[24px] mt-[2%] lg:text-[20px] md:text-[16px] sm:text-[12px] max-sm:text-[12px] montserrat-bold'>Thời gian quay số dự kiến: Ngày 16/01/2024</div>
                    <div className='text-[#162B75] text-center leading-auto italic 2xl:text-[18px] mt-[2%] xl:text-[18px] px-[4%] lg:text-[16px] md:text-[14px] sm:text-[14px] max-sm:text-[10px] montserrat-bold'>Trong vòng 07 ngày kế từ ngày có kết quả quay số trúng thưởng cuối kỳ, SHB sẽ thông báo kết quả trúng thưởng đến từng khách hàng bằng cách gọi điện thoại từ tổng đài chăm sóc khách hàng của các Chi nhánh SHB quản lý khách hàng trúng thưởng đồng thời thông báo công khai trên website SHB <i className='text-[#FFC206]'>www.shb.com.vn</i> và các điểm giao dịch của SHB.</div>
                </div>
                <div className='w-[100%] absolute flex justify-center h-[100%] items-end'>
                    <img src={Btn_close} onClick={() => {
                        _props.onClick();
                    }} className='cursor-pointer absolute w-[45%] mb-[-5%]'></img>
                </div>
                <img src={bg_popup} className='w-[100%]'></img>
            </div>
        </div>
    )
}

export default Infor_result_spin